import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";

export default function TableJari(props) {
  //   console.log(props.data.interessado);
  const { interessado, num_processo, auto, resultado, status, junta } =
    props.data;

  console.log(interessado, num_processo);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Junta</TableCell>
              <TableCell>N° Processo</TableCell>
              <TableCell>Interessado</TableCell>
              <TableCell>Auto</TableCell>
              <TableCell>Resultado</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{junta}</TableCell>
              <TableCell>{num_processo}</TableCell>
              <TableCell>{interessado}</TableCell>
              <TableCell>{auto}</TableCell>
              <TableCell>{resultado}</TableCell>
              <TableCell>{status}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
