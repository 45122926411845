import React, { useState } from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChartIcon from "@material-ui/icons/BarChart";
import LayersIcon from "@material-ui/icons/Layers";
import ReorderIcon from "@material-ui/icons/Reorder";
import BallotIcon from "@material-ui/icons/Ballot";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PersonIcon from "@material-ui/icons/Person";
import { isAuthenticated } from "../services/dados";
import { logout } from "../services/auth";
import { connect } from "react-redux";
import * as actions from "../actions";

export function MainListItems() {
  return (
    <div>
      <ListItem button onClick={() => (window.location = "/")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Início" />
      </ListItem>
      <ListItem button onClick={() => (window.location = "/InserirDiario")}>
        <ListItemIcon>
          <ReorderIcon />
        </ListItemIcon>
        <ListItemText primary="Inserir Diário" />
      </ListItem>
      <ListItem button onClick={() => (window.location = "/importArquivo")}>
        <ListItemIcon>
          <BallotIcon />
        </ListItemIcon>
        <ListItemText primary="Importar Notificação" />
      </ListItem>
      <ListItem button onClick={() => (window.location = "/BuscaPlaca")}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Buscar por Placa" />
      </ListItem>

      <ListItem button onClick={() => (window.location = "/Jari")}>
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="Importar JARI" />
      </ListItem>

      {/* <ListItem button onClick={() => (window.location = "/buscaJari")}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Persquisar JARI" />
      </ListItem> */}
    </div>
  );
}

function SecondaryListItems(props) {
  var logged;
  React.useEffect(() => {
    props.getUser();
  }, []);

  if (isAuthenticated() === true) {
    if (props.usuario === undefined || props.usuario === "") {
      return null;
    }
    logged = (
      <div>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText primary={props.usuario.nome} />
        </ListItem>
        <ListItem button onClick={() => logout()}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </div>
    );
  } else {
    logged = (
      <div>
        <ListItem button onClick={() => (window.location = "/Acessar")}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Acessar" />
        </ListItem>
      </div>
    );
  }
  return (
    <div>
      <ListSubheader inset>Acesso Restrito</ListSubheader>
      {logged}
    </div>
  );
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});
export default connect(mapStateToProps, actions)(SecondaryListItems);
