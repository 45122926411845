import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  TextField,
} from "@material-ui/core";
import { PatternFormat } from "react-number-format";
import moment from "moment";

export default function ImportDiarioData({
  dataPublicacao,
  setDataPublicacao,
  sendToBack,
}) {
  useEffect(() => {
    const dataAtual = new Date();
    const dataConvertida = moment(dataAtual).format("DD/MM/YYYY");
    setDataPublicacao(dataConvertida);
  }, [setDataPublicacao]);

  console.log(dataPublicacao);
  return (
    <Grid component={Card} style={{ padding: "2em" }} item xs={3}>
      <Typography style={{ marginTop: "1em" }}>
        Digite a Data de Publicação
      </Typography>
      <PatternFormat
        style={{ marginTop: "1em" }}
        value={dataPublicacao}
        onChange={(e) => setDataPublicacao(e.target.value)}
        customInput={TextField}
        format="##/##/####"
        variant="outlined"
        label="Data de publicação"
      />
      <br />
      <Button
        style={{ marginTop: "1em" }}
        color="primary"
        variant="contained"
        type="submit"
        onClick={() => sendToBack()}
      >
        Enviar dados
      </Button>
    </Grid>
  );
}
