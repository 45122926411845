// import { Box, Button } from "@material-ui/core";
import {
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { apiCmtt } from "../services/api";
import * as XLSX from "xlsx";
import { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Jari() {
  const classes = useStyles();

  // const [SelectdFile, SetSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [severity, setSeverit] = useState("");
  let [message, setMessage] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    console.log("entrou");
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      console.log(data);
      const workbook = XLSX.read(data, { type: "binary" });
      console.log(workbook.SheetNames);
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parseData = XLSX.utils.sheet_to_json(sheet);

      const newData = parseData.map((item) => {
        return {
          junta: item.JUNTA,
          num_processo: item["N° PROCESSO"],
          interessado: item.INTERESSADO,
          auto: item.AUTO,
          resultado: item.RESULTADO,
          reuniao: item.REUNIAO,
          status: item.STATUS,
        };
      });
      //console.log(newData);
      setData(newData);
    };
  };
  console.log(data);

  const handleClick = async () => {
    setIsLoading(true);

    if (data.length > 0) {
      console.log("Data Maior que Zero");
      const formData = new FormData();
      formData.append(
        "file",
        new Blob([JSON.stringify(data)], { type: "application/json" })
      );
      console.log(formData);

      const response = await apiCmtt
        .post("/jari", { jaris: data })
        .then((response) => {
          setIsLoading(false);
          setOpen(true);
          setSeverit("success");
          setMessage("Eviado com sucesso");

          return response;
        })
        .catch((error) => {
          console.log("erro ao enviar arquivo" + error);
          setIsLoading(false);
          setSeverit("error");
          setOpen(true);
          setMessage("Erro ao enviar o arquivo");
        });
      setData(0);
      return response;
    }
  };

  console.log(message);

  return (
    <div align={"center"}>
      <Typography align={"center"} variant={"h4"}>
        Importar Arquivo
      </Typography>
      {!data.length && !isLoading ? (
        <label style={{ marginTop: "2rem" }} htmlFor="upload-JARI">
          <input
            onChange={handleChange}
            style={{ display: "none" }}
            id="upload-JARI"
            name="upload-JARI"
            type="file"
          />
          <Button
            style={{ marginTop: "2rem" }}
            color="secondary"
            variant="contained"
            component="span"
            onClick={() => setOpen(false)}
          >
            Selecione o Arquivo
          </Button>
        </label>
      ) : (
        <Grid
          component={Card}
          style={{ padding: "2em", marginTop: "2em" }}
          item
          xs={3}
        >
          <Typography variant="h5">Upload de JARI</Typography>
          <Typography style={{ marginTop: "1em" }}>
            Quantidade de JARI carregadas: {data.length}
          </Typography>

          <Button
            style={{ marginTop: "1em" }}
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleClick}
          >
            Enviar dados
          </Button>
        </Grid>
      )}
      {isLoading && (
        <Box marginTop={5}>
          <LinearProgress />
        </Box>
      )}
      <br /> <br />
      <br />
      <div className={classes.root}>
        <Collapse in={open}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Collapse>

        {/* <Button
          disabled={open}
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
        >
          Re-open
        </Button> */}
      </div>
    </div>
  );
}
