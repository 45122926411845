import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { postDiario } from "../actions";
import ImportDiarioData from "../components/importar/ImportDiarioData";
import moment from "moment";
import { Toast } from "../components/Alert";
import { useHistory } from "react-router-dom";

export default function InserirDiario() {
  const [fileToSave, setFileToSave] = useState(null);
  const [fileNameToSave, setFileNameToSave] = useState("");
  const [dataPublicacao, setDataPublicacao] = useState("");
  const history = useHistory();

  const handleUploadFile = async (event) => {
    let selectedFile = event.target.files;
    let file = null;
    let fileName = "";
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      let fileToLoad = selectedFile[0];
      fileName = fileToLoad.name;
      // FileReader function for read the file.
      let fileReader = new FileReader();
      // Onload of file read the file content
      fileReader.onload = async (fileLoadedEvent) => {
        file = fileLoadedEvent.target.result;
        // Print data in console
        setFileToSave(file);
        setFileNameToSave(fileName);
      };
      // Convert data to base64
      fileReader.readAsDataURL(fileToLoad);
    }
  };

  const sendToBack = async () => {
    const application = "CMTTMULTAS";
    const idRegister = fileNameToSave;
    const tipo = "DIARIO";
    const cpf = "00000000000";
    const date = moment(dataPublicacao, "DD/MM/YYYY").format("YYYY-MM-DD");
    // const dateSend = moment(date).format("yyyy-mm-dd");

    const queryData = {
      application,
      idRegister,
      tipo,
      cpf,
      dataPublicacao: date,
    };

    const image64 = fileToSave;

    await postDiario(image64, queryData)
      .then(async (res) => {
        await Toast.fire({
          icon: "success",
          title: "sucesso",
        });
        // history.go(0);
      })
      .catch((err) => {
        Toast.fire({
          icon: "error",
          title: err.message,
        });
      });
  };

  return (
    <div align="center">
      <Typography align={"center"} variant={"h4"}>
        Importar Diário
      </Typography>
      {fileToSave ? (
        <ImportDiarioData
          dataPublicacao={dataPublicacao}
          setDataPublicacao={setDataPublicacao}
          sendToBack={sendToBack}
        />
      ) : (
        <label style={{ marginTop: "2rem" }} htmlFor="upload-photo">
          <input
            onChange={(e) => handleUploadFile(e)}
            style={{ display: "none" }}
            id="upload-photo"
            name="upload-photo"
            type="file"
          />
          <Button
            style={{ marginTop: "2rem" }}
            color="secondary"
            variant="contained"
            component="span"
          >
            Selecione o Arquivo
          </Button>
        </label>
      )}
    </div>
  );
}
