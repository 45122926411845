import { Box, Grid } from "@material-ui/core";
import { CardComponent } from "../components/card-component/CardComponent";

export default function Inicial() {
  const notificacao = {
    title: "Edital de Notificação ",
    icon: "summarize",

    links: [
      {
        path: "/BuscaPlaca",
        label: "Consulte notificação de autuação ou penalidade",
      },
    ],
  };

  const formularios = {
    title: "Formulários",
    icon: "checklist-rtl",
    teste: "bla, bla, bla",

    links: [
      {
        path: "https://cdn.anapolis.go.gov.br/arquivos/cmtt/FORMUL%C3%81RIO_REQUERIMENTOS_DIVERSOS_CMTT.pdf",
        label: "Requerimentos Diversos",
      },
      {
        path: "https://cdn.anapolis.go.gov.br/arquivos/cmtt/FORMUL%C3%81RIO_DEFESA_PR%C3%89VIA_OU_RECURSO_CMTT.pdf",
        label: "Defesa Prévia ou Recurso",
      },
      {
        path: "https://cdn.anapolis.go.gov.br/arquivos/cmtt/FORMUL%C3%81RIO_IDENTIFICA%C3%87%C3%83O_DE_CONDUTOR%20INFRATOR_CMTT.pdf",
        label: "Identificação de Condutor Infrator",
      },
    ],
  };

  const fiscalizacao = {
    title: "Fiscalização Eletrônica",
    icon: "traffic",

    links: [
      {
        path: "https://earth.google.com/web/data=MicKJQojCiExTHlERFYzMjNtc2VuZlFQZFRrSkVRZktTLUM5bmJLd0k6AwoBMA?authuser=0",
        label: "Confira no Mapa os Pontos de Fiscalização Eletrônica",
      },
      {
        path: "https://drive.google.com/drive/folders/157Pag5Xl4lzTHEOFXrjAvlGmXRoGWiS1",
        label: "Estudos Técnicos dos Equipamentos de Fiscalização Eletrônica",
      },
    ],
  };

  return (
    <>
      <Box
        height="70vh"
        display="flex"
        justifyContent="flex"
        alignItems="center"
      >
        <Box
          height="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CardComponent conteudo={formularios} />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CardComponent conteudo={fiscalizacao} />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CardComponent conteudo={notificacao} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
