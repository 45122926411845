import { Box, Paper, Icon, Typography } from "@material-ui/core";

import MeuCard from "../MeuCard";
// import { makeStyles } from "@material-ui/core/styles";

export const CardComponent = ({ conteudo, handleClickOpen }) => {
  return (
    <Box
      component={Paper}
      sx={{
        boxSizing: "border-box",
      }}
      width="100%"
      height="250px"
      display="flex"
      flexDirection="column"
      //bgcolor="yellow"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={2}
        width="100%"
        height="70px"
        bgcolor="#154387"
        sx={{ borderRadius: "5px" }}
      >
        <Icon fontSize="large" style={{ color: "white", margin: "4px" }}>
          {conteudo.icon}
        </Icon>
        <Typography
          variant="h5"
          style={{ color: "white", margin: "4px" }}
          textAlign="center"
        >
          {conteudo.title}
        </Typography>
      </Box>

      <Box
        height="100%"
        sx={{
          //     // backgroundImage: 'url("https://www.anapolis.go.gov.br/wp-content/themes/prefeitura-anapolis/assets/images//monumentos-anapolis.png")',
          backgroundImage: `url(${conteudo.bg})`,

          backgroundSize: "200px",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(0,0,0,0.1)"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {conteudo.links.map((item, index) => {
            return (
              <MeuCard
                item={item}
                conteudo={conteudo}
                handleClickOpen={handleClickOpen}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
