import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useState } from "react";

export default function MeuCard({ item, conteudo }) {
  const [open, setOpen] = useState(false);
  const [myLink, setMyLink] = useState(" ");

  const handleClickOpen = () => {
    setOpen(true);
    setMyLink(item.path);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {conteudo.title === "Formulários" ? (
        <Box padding="3px" margin="3px" textAlign="center" alignItems="center">
          <Button onClick={handleClickOpen}>
            <Typography variant="button">{item.label} </Typography>
          </Button>
        </Box>
      ) : (
        <Box padding="3px" margin="3px" textAlign="center" alignItems="center">
          <Button>
            <a href={item.path}>
              <Typography variant="button">{item.label} </Typography>
            </a>
          </Button>
        </Box>
      )}

      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>
                Após preenchidos, os formulários poderão ser enviados para o
                e-mail: protocolocmtt@anapolis.go.gov.br ou entregues na
                Companhia Municipal de Transito e Transporte (CMTT) situada na
                Av.Brasil Sul, 7575, bairro Vila Esperança - Anápolis - GO
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              <a href={myLink} target="_blanck">
                Prosseguir
              </a>
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
