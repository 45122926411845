import { Box, TextField, Typography, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { apiCmtt } from "../services/api";
import TableJari from "../components/table/TableJari";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function BuscaJari() {
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [process, setProcess] = useState("");
  const [data, setData] = useState("");

  const handleProcess = (e) => {
    e.preventDefault();
    setProcess(e.target.value);
  };
  console.log(process);

  // const handleTeste = () => {
  //   console.log(isLoading);
  //   setIsLoading(true);
  //   console.log(isLoading);
  // };
  // console.log(isLoading);

  const handleClick = async () => {
    // console.log(isLoading);
    setIsLoading(true);
    setMessage(" ");
    // console.log(isLoading);
    const response = await apiCmtt
      .get(`/jari/${process}`)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        console.log("erro ao enviar arquivo" + error);
        setIsLoading(false);
      });
    if (data.length <= 0) {
      setMessage("Sem Resultados Para a Pesquisa");
    }
    console.log(data);

    console.log(isLoading);

    return response;

    // if (data) {
    //   return data;
    // }
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      //   height="100vh"
      justifyContent="center"
      alignItems="center"
      //   bgcolor="yellow"
    >
      <Box
        margin="auto"
        //    bgcolor="blue"
      >
        <Typography variant="h3">JARI</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="30%"
        //bgcolor="green"
        padding={2}
        marginTop={2}
        flexDirection="column"
      >
        <TextField
          id="busca_jari"
          name="busca_jari"
          label="Número do Processo:"
          fullWidth
          onChange={handleProcess}
          // style={{ display: "none" }}
        />

        <Button
          onClick={handleClick}
          //   width="100%"
          variant="contained"
          color="primary"
          style={{ width: "100%", marginTop: "24px", padding: "10px" }}
          startIcon={<SearchIcon />}
        >
          Buscar JARI
        </Button>
      </Box>

      <Box>
        <Box>{isLoading && <LinearProgress />}</Box>
        <Box marginTop={6}>
          {data ? <TableJari data={data} /> : <h3>{message}</h3>}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        padding={4}
        marginY={5}
      >
        <Typography>
          Consulte o resultado do Recurso da Penalidade de Auto de Infração de
          Trânsito, apresentado perante a Junta Administrativa de Recursos de
          Infrações (JARI), por meio do número do auto de infração ou do número
          de protocolo do processo. A consulta se encontra disponível para os
          recursos protocolizados a partir de 01 de janeiro de 2024, de
          competência da Prefeitura de Anápolis. O erro na digitação do número
          do auto de infração ou do número do protocolo do recurso inviabilizará
          a consulta. Dúvidas entrar em contato na JARI da Companhia Municipal
          de Trânsito e Transportes (CMTT): (62) 3902-2824
        </Typography>
      </Box>
    </Box>
  );
}
